<template>
    <div class="root">
        <headers></headers>
        <div class="get-lay max-width-all">
            <div class="left">
                <h1>{{ $t('user.title') }}</h1>
                <p>{{ $t('user.desc') }}</p>
            </div>
            <div class="right">
                <div class="info">
                    <img :src="require('../../static/images/v' + grade + '.png')" />
                    <div class="info-right">
                        <p>{{ $t('user.date') }}: {{regTime}}</p>
                        <div>
                            <div>{{ $t('user.type1') }}: <span class="num">{{shareTotal}}</span></div>
                            <div>{{ $t('user.type2') }}: <span class="num">{{teamTotal}}</span></div>
                            <div>{{ $t('user.type3') }}: <span class="num">${{stakeTotal}}</span></div>
                            <div>{{ $t('user.type4') }}: <span class="num">${{teamStakeTotal}}</span></div>
                        </div>
                    </div>
                </div>
                <h2>{{ $t('user.my') }}</h2>
                <div class="copy">
                    <div>{{inviteLink ? inviteLink : '-'}}</div>
                    <button  v-if="inviteLink" @click="copy()">{{ $t('user.copy') }}</button>
					<button  v-else class="disabled">{{ $t('user.copy') }}</button>
                </div>
            </div>
        </div>
        <!-- 我的社区 -->
        <div class="record-title max-width-all">
            <span>
                {{ $t('user.myCommunity') }}
            </span>
        </div>
        <!-- 列表 -->
        <div class="list-lay">
            <div class="address-lay">
                <span class="title">{{$t('user.cur')}}:</span>
                <div class="add-list" v-for="(item, index) in teams.addressList" :key="index">
<!--                    <span :class="index==showAddressList.length-1?'get-address':''" @click="getSubTeamList(item)">{{ handleAddress(item) }}</span>-->
<!--                    <img src="../../static/images/right.png" v-if="index!=showAddressList.length-1" />-->
					<span :class="index==teams.addressList.length-1?'get-address':''" @click="getSubTeamList(item.address)" v-if="item.address">{{ item.showAddress }}</span>
					<span :class="index==teams.addressList.length-1?'get-address':''" v-else>{{ item.showAddress }}</span>
					<span v-if="index!=teams.addressList.length-1">></span>
                </div>
            </div>
            <div class="list" v-for="item in teams.list" :key="item">
                <div class="left">
                    <img class="grade-img" :src="require('../../static/images/v' + item.grade + '.png' )" />
                    <div>
                        <p>{{item.showAddress}} <span style="background-color: #dddddd;padding: 4px 10px;border-radius: 4px;cursor: pointer" @click="copyAddress(item.address)">{{ $t('user.copy') }}</span></p>
                        <span>{{ $t('user.date') }}: {{item.regTime}}</span>
                        <div class="mobile">
                            <span>{{ $t('user.type1') }}: <span class="num">{{item.share}}</span></span>
                            <span>{{ $t('user.type2') }}: <span class="num">{{item.team}}</span></span>
                            <span>{{ $t('user.type3') }}: <span class="num">${{item.stakeRemainTotal}}</span></span>
                            <span>{{ $t('user.type4') }}: <span class="num">${{item.teamStakeTotal}}</span></span>
                        </div>
                        <button @click="getSubTeamList(item.address)">{{ $t('user.look') }}
                            <img src="../../static/images/right-user.png" />
                        </button>
                    </div>
                </div>
                <div class="right pc">
                    <div>
                        <span>{{ $t('user.type1') }}:<span class="num">{{item.share}}</span></span>
                        <span>{{ $t('user.type2') }}:<span class="num">{{item.team}}</span></span>
                    </div>
                    <div>
                        <span>{{ $t('user.type3') }}:<span class="num">${{item.stakeRemainTotal}}</span></span>
                        <span>{{ $t('user.type4') }}:<span class="num">${{item.teamStakeTotal}}</span></span>
                    </div>
                </div>
            </div>
            <!-- 暂无数据 -->
            <div class="no-data" v-if="teams.totalRecord - 0 <= 0">
                <img src="../../static/images/no-data.png" />
            </div>
            <!-- 更多 -->
            <span class="more" v-if="teams.page < teams.totalPage" @click="getNextTeamList">{{ $t('award.more') }}</span>
        </div>
        <p class="copyright">© 2023 ID LABS. All rights reserved.</p>

    </div>
</template>
<script setup>
    import headers from '@/components/headers'

	import i18n from "@/i18n"
	import { ElMessage } from 'element-plus'

	import {memberStore} from "@/store/member"
	import {walletStore} from "@/store/wallet"
	import {webStore} from "@/store/web"
	import {computed, onMounted, reactive, ref, watch} from "vue"

	import {queryIndexInfo, queryTeamList} from '@/api/user'
	import Util from "@/utils/common-util";
	import {start, end} from "@/api/loading";
	import {send} from "@/contract/web3-util";
	import Web3 from "web3";

	const mStore = memberStore()
	const wStore = walletStore()
	const wbStore = webStore()

	const grade = ref(0)
	const regTime = ref('-')
	const shareTotal = ref(0)
	const teamTotal = ref(0)
	const stakeTotal = ref(0)
	const teamStakeTotal = ref(0)

	const teams = reactive({
		addressList: [],
		curAddress: '',
		page: 1,
		pageSize: 0,
		totalPage: 0,
		totalRecord: 0,
		list: [],
	})

	const inviteLink = computed(() => {
		if(wStore.curAccount && mStore.memberInfo.uid > 0) {
			return process.env.VUE_APP_BASE_URL + '/#/?invite=' + wStore.curAccount
		}

		return ''
	})

    const handleAddress = (str) =>{
        return str.substring(0,10) + '...' + str.substring(str.length - 10)
    }

	// const list = computed(()=>{
	// 	if (!Util.isDefine(teams.addressList)) {
	// 		return
	// 	}
	// 	if(teams.addressList.length > 4){
	// 		let arr = [];
	// 		arr[0] = teams.addressList[0];
	// 		arr[1] = '...';
	// 		arr[2] = teams.addressList[teams.addressList.length - 2];
	// 		arr[3] = teams.addressList[teams.addressList.length - 1];
	// 		return arr;
	// 	}else{
	// 		return teams.addressList
	// 	}
	// })

	const copy = () =>{
		if (wStore.curAccount && mStore.memberInfo.uid > 0) {
			const value = process.env.VUE_APP_BASE_URL + '/#/?invite=' + wStore.curAccount
			const input = document.createElement('input')
			input.value = value
			input.id = 'creatDom'
			document.body.appendChild(input)
			input.select()
			document.execCommand('copy')
			document.body.removeChild(input)
			ElMessage({
				type: 'success',
				message: i18n.global.t('alert.copied')
			})
		}
	}

	const copyAddress = (value) =>{
		if (value) {
			const input = document.createElement('input')
			input.value = value
			input.id = 'creatDom'
			document.body.appendChild(input)
			input.select()
			document.execCommand('copy')
			document.body.removeChild(input)
			ElMessage({
				type: 'success',
				message: i18n.global.t('alert.copied')
			})
		}
	}

	function getIndexInfo() {
		if (!wbStore.isLogin()) {
			return
		}

		queryIndexInfo().then(result => {
			if (Util.isDefine(result) && result.data.code === 1) {
				const time = Util.timestampToDate(result.data.data.bindTime)
				grade.value = result.data.data.grade
				regTime.value = new Intl.DateTimeFormat('en-US', { dateStyle: 'long'}).format(new Date(result.data.data.bindTime * 1000)) + ' ' + time.time
				shareTotal.value = Util.numFormatBase(result.data.data.shareTotal - 0)
				teamTotal.value = Util.numFormatBase(result.data.data.teamTotal - 0)
				stakeTotal.value = Util.numFormat4Floor(result.data.data.stakeTotal - 0)
				teamStakeTotal.value = Util.numFormat4Floor(result.data.data.teamStakeTotal - 0)
			}
		})
	}

	function getSubTeamList(address) {
		if (!wbStore.isLogin()) {
			return
		}

		teams.list = []
		teams.page = 1
		teams.pageSize = 0
		teams.totalPage = 0
		teams.totalRecord = 0

		teams.curAddress = address

		getTeams()
	}

	function getTeamList() {
		if (!wbStore.isLogin()) {
			return
		}

		teams.list = []
		teams.page = 1
		teams.pageSize = 0
		teams.totalPage = 0
		teams.totalRecord = 0

		teams.curAddress = ''

		getTeams()
	}
	function getNextTeamList() {
		if (!wbStore.isLogin()) {
			return
		}

		if (teams.page < teams.totalPage) {
			teams.page++
		} else {
			return
		}

		getTeams()
	}

	function getTeams() {
		if (!wbStore.isLogin()) {
			return
		}

		let subAddress = teams.curAddress

		queryTeamList({page: teams.page, pageSize: 6, account: subAddress}).then(function (result) {
			if (Util.isDefine(result) && result.data.code === 1) {
				const data = result.data.data.pagingList
				teams.page = data.currPage
				teams.pageSize = data.pageSize
				teams.totalPage = data.totalPage
				teams.totalRecord = data.totalRecord

				const addrList = result.data.data.addressList
				teams.addressList = []
				const length = Util.isDefine(addrList) ? addrList.length : 0
				for (let i = length - 1 ; i >= 0 ; i--) {
					if (addrList[i]) {
						teams.addressList.push({
							address: addrList[i],
							showAddress: '...' + addrList[i].substring(38)
						})
					} else {
						teams.addressList.push({
							address: '',
							showAddress: '...'
						})
					}
				}

				const list = data.resultList
				for (let i = 0; Util.isDefine(list) && i < list.length; i++) {
					const a = list[i]
					const member = {}
					member.address = a.address
					member.showAddress = a.address.substring(0, 10) + '...' + a.address.substring(36)
					const time = Util.timestampToDate(a.bindTime)
					member.regTime = new Intl.DateTimeFormat('en-US', { dateStyle: 'long'}).format(new Date(a.bindTime * 1000)) + ' ' + time.time
					member.grade = a.grade
					member.share = Util.numFormatBase(a.shareTotal)
					member.team = Util.numFormatBase(a.teamTotal)
					member.stakeRemainTotal = Util.numFormat4Floor(a.stakeRemainTotal - 0)
					member.teamStakeTotal = Util.numFormat4Floor(a.teamStakeTotal - 0)

					teams.list.push(member)
				}
			}
		})
	}

	onMounted(() => {
		getIndexInfo()
		getTeamList()
	})

	const curAccount = computed(()=>{
		return wStore.curAccount
	})
	watch(curAccount, (newVal, oldVal) =>{
		if (newVal) {
			if (oldVal) {
				alert(i18n.global.t('alert.reLogin'))
			}
			wbStore.accountLogin().then((result) => {
				wbStore.setLoginState(true)
				mStore.queryMemberInfo()

				getIndexInfo()
				getTeamList()
			}).catch((reason) => {
				wbStore.setLoginState(false)
				console.log(reason)
				console.error('login failed')
			})
		}
	})
</script>
<style scoped>
    .root{
        color: white;
        padding-bottom: 20px;
        background: linear-gradient(90deg, #172929 0%, #0D1113 50%, #0E152B 100%);
    }
    .get-lay{
        width: calc(100% - 320px);
        margin: 100px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .get-lay .left p{
        width: 400px;
        font-size: 12px;
        color: #BECFD2;
    }
    .get-lay .right{
        width: 400px;
        color: black;
        padding: 24px;
        background-color: #E9FFF7;
        border-radius: 24px;
    }
    .get-lay img{
        width: 96px;
        height: 96px;
        margin-right: 5px;
    }
    .get-lay .right .info{
        display: flex;
        font-size: 12px;
        color: rgba(13,17,19,0.75);
    }
    .get-lay .num{
        color: #0D1113;
    }
    .get-lay .info-right > div{
        display: flex;
        flex-wrap: wrap;
    }
    .get-lay .info-right > div div{
        width: 50%;
        margin-bottom: 10px;
    }
    .get-lay .left h1{
        margin-top: 30px;
    }
    .get-lay .right .copy{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
        padding: 4px;
        padding-left: 16px;
        color: #005474;
        font-size: 14px;
    }
    .get-lay .right .copy div{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 16px;
    }
    .get-lay .right .copy button{
        font-size: 12px;
        min-width: 88px;
        max-width: 88px;
        height: 40px;
        color: black;
        background-color: #20EEA4;
        border-radius: 8px;
    }
	.get-lay .right .copy button.disabled{
		background-color: #dddddd;
		color: #999999;
	}
    .record-title{
        width: calc(100% - 320px);
        margin: 0 auto;
    }
    .record-title span{
        font-size: 18px;
        font-weight: bold;
        background: linear-gradient(to left, #40FFF4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 3px solid #20EEA4;
        padding-bottom: 5px;
    }
    .list-lay{
        background-color: #1C282C;
        padding: 56px 160px;
        margin-top: 8px;
    }
    .address-lay{
        max-width: 1200px;
        margin: 0 auto 40px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 12px;
    }
    .address-lay .title{
        white-space: nowrap;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .address-lay .add-list{
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .address-lay .add-list span:first-child{
        text-decoration: underline;
        cursor: pointer;
        margin-right: 10px;
    }
    .address-lay img{
        width: 16px;
        height: 16px;
    }
    .address-lay .get-address{
        color: #20EEA4;
    }
    .list-lay .list{
        max-width: calc(1200px - 48px);
        margin: 0 auto 24px;
        display: flex;
        justify-content: space-between;
        padding: 24px;
        border-radius: 12px;
        color: black;
        background-color: #E9FFF7;
    }
    .list .left{
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .list .grade-img{
        width: 75px;
        height: 75px;
    }
    .list .left div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 16px
    }
    .list .left p{
        color: #005474;
        font-weight: bold;
    }
    .list .left span{
        color: rgba(13,17,19,0.75);;
        margin-bottom: 10px;
    }
    .list .left button{
        color: #005474;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,84,116,0.15);
        padding: 5px 10px;
        border-radius: 5px;
    }
    .list .left button img{
        width: 20px;
    }
    .list .right{
        width: 400px;
        height: 100%;
        font-size: 12px;
        color: rgba(13,17,19,0.75);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .list .right div{
        display: flex;
        margin-bottom: 10px;
    }
    .list .right div span{
        width: 50%;
    }

    .mobile{
        display: none !important;
    }
    .num{
        color: #0D1113;
    }
    @media screen and (max-width: 900px) {
        .root{
            padding-bottom: 70px;
        }
        .get-lay{
            width: calc(100% - 64px);
            margin: 30px auto 48px;
            flex-direction: column;
            align-items: flex-start;
        }
        .get-lay .left p{
            width: 100%;
            margin-bottom: 20px;
        }
        .get-lay .right{
            width: calc(100% - 32px);
            padding: 16px;
        }
        .get-lay .right button{
            width: 80px;
        }
        .get-lay img{
            width: 75px;
            height: 75px;
        }
        .record-title{
            width: calc(100% - 64px);
        }
        .get-lay .info-right > div div{
            width: 100%;
        }
        .list-lay{
            padding: 23px 30px;
        }
        .list .left{
            align-items: flex-start;
        }
        .pc{
            display: none !important;
        }
        .mobile{
            display: flex !important;
            margin-left: 0 !important;
        }
        .list button{
            margin-top: 10px;
        }
        .grade-img{
            width: 96px;
            height: 96px;
        }
    }
</style>
